<template>
  <div>
    <a-modal
      width="500px"
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="新增供应商"
      :body-style="{ color: 'red' }"
      @ok="handleSubmit"
    >
      <a-form
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
        class="simiot-compact-form"
        @submit="handleSubmit"
      >
        <a-row>
          <a-col :span="24">
            <a-form-item label="供应商名称">
              <a-input
                v-decorator="['name', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { required: true, message: '请输入供应商名称' },
                    { max: 50, message: '最多50个字符' }
                  ]
                }]"
              />
            </a-form-item>

            <a-form-item label="联系人">
              <a-input
                v-decorator="['contact', {
                  normalize: this.$lodash.trim,
                  rules: [{ max: 50, message: '最多50个字符' }
                  ]
                }]"
              />
            </a-form-item>

            <a-form-item label="联系邮箱">
              <a-input
                v-decorator="['email', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { pattern: /^[a-z0-9._%-]+@([a-z0-9-]+\.)+[a-z]{2,4}$/, message: '联系邮箱格式不正确' },
                  ]
                }]"
              />
            </a-form-item>

            <a-form-item label="联系电话">
              <a-input
                v-decorator="['phone_number', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { pattern: /^1[3-9]\d{9}$/, message: '联系电话格式不正确' },
                  ]
                }]"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>
<script>

import { createSupplierAccount } from '@/api/supplier_account'

export default {
  name: 'NewSupplierAccount',
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  components: {
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'supplier_account' }),
      submitting: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values
          }
          createSupplierAccount(data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
