var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"width":"500px","centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"新增供应商","body-style":{ color: 'red' }},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"simiot-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 16 }},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"供应商名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
                normalize: this.$lodash.trim,
                rules: [
                  { required: true, message: '请输入供应商名称' },
                  { max: 50, message: '最多50个字符' }
                ]
              }]),expression:"['name', {\n                normalize: this.$lodash.trim,\n                rules: [\n                  { required: true, message: '请输入供应商名称' },\n                  { max: 50, message: '最多50个字符' }\n                ]\n              }]"}]})],1),_c('a-form-item',{attrs:{"label":"联系人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['contact', {
                normalize: this.$lodash.trim,
                rules: [{ max: 50, message: '最多50个字符' }
                ]
              }]),expression:"['contact', {\n                normalize: this.$lodash.trim,\n                rules: [{ max: 50, message: '最多50个字符' }\n                ]\n              }]"}]})],1),_c('a-form-item',{attrs:{"label":"联系邮箱"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['email', {
                normalize: this.$lodash.trim,
                rules: [
                  { pattern: /^[a-z0-9._%-]+@([a-z0-9-]+\.)+[a-z]{2,4}$/, message: '联系邮箱格式不正确' } ]
              }]),expression:"['email', {\n                normalize: this.$lodash.trim,\n                rules: [\n                  { pattern: /^[a-z0-9._%-]+@([a-z0-9-]+\\.)+[a-z]{2,4}$/, message: '联系邮箱格式不正确' },\n                ]\n              }]"}]})],1),_c('a-form-item',{attrs:{"label":"联系电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone_number', {
                normalize: this.$lodash.trim,
                rules: [
                  { pattern: /^1[3-9]\d{9}$/, message: '联系电话格式不正确' } ]
              }]),expression:"['phone_number', {\n                normalize: this.$lodash.trim,\n                rules: [\n                  { pattern: /^1[3-9]\\d{9}$/, message: '联系电话格式不正确' },\n                ]\n              }]"}]})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }